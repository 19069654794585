@import '../../styles/index';

.base {
  position: relative;
  margin: 1rem 0;

  @include media-from(sm) {
    margin: 0 0 1.5rem 0;
  }
}

.step {
  flex: 0 0 100%;
}

.content-wrapper {
  overflow: hidden;
}
