@import '../../styles/index';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;

  @include media-from(sm) {
    margin-bottom: 0.5rem;
  }

  .title {
    color: $neutral-12;
    font-size: $font-size-24;
    font-weight: $font-weight-medium;
    line-height: 1.33;
    margin-right: 1rem;

    @include media-from(sm) {
      font-size: $font-size-28;
    }
  }
}
