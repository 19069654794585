@import '../../../styles/index';

.base {
  font-size: $font-size-18;
  font-weight: $font-weight-light;
  max-width: 40rem;
  margin: 0 auto 0.75rem auto;
  text-align: center;
  line-height: 1.6;

  @include media-from(sm) {
    font-size: $font-size-22;
    margin: 0 auto 1rem auto;
  }

  a {
    color: $surrogate-9;
    position: relative;
    transform: scale3d(0, 0, 0);
    transition: text-decoration $duration-short ease-in-out;
    top: 0;
    cursor: pointer;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
