@import '../../styles/index';

.base {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  user-select: none;
  transition: transform 500ms ease-in-out;

  > :last-child {
    margin-bottom: 0;
  }

  @include media-from(sm) {
    justify-content: flex-end;
  }
}
