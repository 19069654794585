@import '../../styles/index';

.base {
  position: relative;
}

.slides {
  background-color: $surrogate-1;
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  margin: 0;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 0;
  scroll-snap-type: x mandatory;
  transition: max-height 0.3s ease-out;

  &::-webkit-scrollbar {
    display: none;
  }
}

.slide {
  align-items: center;
  display: block;
  flex: 1 0 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  scroll-snap-align: center;
  width: 100%;
}

.slide-content {
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  width: 100%;
}

.control-button {
  all: unset;
  align-items: center;
  aspect-ratio: 1;
  background-color: $neutral-0;
  border-radius: $border-radius-full;
  box-shadow: $shadow--small;
  color: $surrogate-9;
  cursor: pointer;
  display: none;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 3.125rem;
  z-index: 1;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.left {
    left: 1.5rem;
  }

  &.right {
    right: 1.5rem;
  }

  @include media-from(sm) {
    display: flex;

    &::before {
      aspect-ratio: 1;
      background-color: $surrogate-9;
      background: transparent;
      border-radius: $border-radius-full;
      content: '';
      display: block;
      height: 100%;
      opacity: 0;
      outline: 0.375rem solid rgba($neutral-0, 0.4);
      position: absolute;
      transition: opacity $duration-default;
      width: 100%;
    }

    &:focus-visible,
    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }
}

.indicators {
  bottom: 1.5rem;
  display: flex;
  gap: 1rem;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.indicator {
  all: unset;

  aspect-ratio: 1;
  background-color: rgba($neutral-0, 0.4);
  border-radius: $border-radius-full;
  cursor: pointer;
  width: 1rem;

  &:focus {
    outline: 0.375rem solid rgba($neutral-0, 0.4);

    &:not(.active) {
      background-color: $neutral-8;
      border: 2px solid $neutral-0;
    }
  }

  &.active {
    background-color: $neutral-0;
  }
}
