@import '../../../styles/index';

.base {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 2.25rem;
  transition: transform 500ms ease-in-out;

  @include media-from(sm) {
    min-height: 450px;
    margin-bottom: 7.5rem;
  }
}
