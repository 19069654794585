@import '../../../styles/index';

.base {
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;

  @include media-from(sm) {
    display: block;
    visbility: hidden;
    opacity: 0;
    transition: opacity 250ms ease-in-out;

    &.active {
      visibility: visibile;
      opacity: 1;
    }
  }
}

.prev {
  left: 0;
}

.next {
  right: 0;
}
