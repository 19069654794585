@import '../../styles/index';

.base {
  background-color: $surrogate-2;
  border-radius: 0.5rem;
  padding: 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.75rem;

  @include media-from(xs) {
    padding: 1.5rem;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
}

.filter-option {
  appearance: none;
  height: 2.25rem;
  padding: 0 1.125rem;
  background-color: transparent;
  border: solid 0.125rem $surrogate-9;
  border-radius: 1.125rem;
  color: $surrogate-9;
  cursor: pointer;

  font-weight: $font-weight-medium;
  font-size: $font-size-16;
  line-height: 1.25;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  transition: transform $duration-default, background-color $duration-default,
    color $duration-default;

  &:hover {
    transform: translateY(-3px);
  }

  &:focus {
    outline: none;
    box-shadow: $shadow-button-focus;
  }

  &:active {
    transform: translateY(0px);
  }

  &.active {
    background-color: $surrogate-9;
    color: $neutral-0;
  }
}
