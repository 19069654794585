@import '../../../styles/index';

.base {
  margin: 0;
}

.title {
  font-size: $font-size-24;
  font-weight: $font-weight-medium;
  text-align: center;
  margin: 0 auto 0.75rem auto;
  max-width: 33rem;
  line-height: 1.25;

  @include media-from(sm) {
    font-size: $font-size-30;
    margin: 0 auto 1rem auto;
  }
}

.icon {
  display: flex;
  justify-content: center;
  margin-bottom: 0.75rem;
  color: $surrogate-9;

  @include media-from(sm) {
    margin-bottom: 1rem;
  }
}
