@import '../../../styles/index';

.base {
  font-size: $font-size-16;
  font-weight: $font-weight-light;
  margin: 0 0 1rem 0;
  text-align: center;
  line-height: 1.6;

  > strong {
    font-weight: $font-weight-medium;
  }

  @include media-from(sm) {
    font-size: $font-size-18;
    max-width: 40rem;
    margin: 0 auto 0.75rem auto;
  }
}

.link {
  color: $surrogate-9;
  font-weight: $font-weight-medium;
}
