@import '../../styles/index';

.wrapper {
  padding-bottom: 2rem;
  position: relative;
}

.content {
  border-radius: $border-radius-default $border-radius-default;
  width: calc(100vw - 1rem);
  padding: 0;
  margin-top: 4rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: $index-modal-conent;

  > div:not(:last-child) {
    @include space-between-containers();
  }

  @include media-from(sm) {
    min-height: unset;
    margin-top: 6.25rem;
    margin-bottom: 3rem;
    padding: 0;
  }

  @include media-from(xs) {
    width: calc(100vw - 1rem);
    max-width: 1124px;
    border-radius: $border-radius-default;
    padding: 0;
  }
}

.white {
  background-color: $neutral-0;
}

.none {
  background-color: transparent;
  box-shadow: none;
}
