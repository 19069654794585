@import 'src/uikit/styles/index';

.base {
  margin: 0;
  width: 100%;
  justify-self: stretch;
}

.grid {
  display: grid;
  gap: 1.25rem;
  width: 100%;
  margin-bottom: 2rem;
  align-items: stretch;

  @include media-from(xs) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-from(sm) {
    &.three-cols {
      grid-template-columns: repeat(3, 1fr);
    }

    &.four-cols {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &-item {
    width: 100%;
  }
}

.load-more-button {
  display: flex;
  margin: 3rem auto 0;
}
